import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toastError, toastSuccess } from '../../alertas';
import { postData } from '../../servicios';
import FormError from '../common/FormError';
import { updateHeaders, updateUser } from '../../actions/index';

export class CardsForm extends Component {
    constructor(props) {
        super(props);
        const { user } = this.props;

        this.state = {
            directions: user.directions,
            loading: false,
            cards: user.cards,
        };
    }

    handleSubmit = (e) => {
        e.preventDefault();
    };

    handleCardChange = (e, index) => {
        const { name, value, type } = e.target;
        const { cards } = this.state;
        const list = [...cards];

        list[index][name] = value;

        this.setState({
            cards: list,
        });
    };

    handleAddCards = (e) => {
        e.preventDefault();
        const { cards } = this.state;
        this.setState({
            cards: [
                ...cards,
                {
                    name: '',
                    number: '',
                    month: '',
                    year: '',
                    cvc: '',
                    df: true,
                },
            ],
        });
    };

    handleRemoveCards = (e, i) => {
        e.preventDefault();
        const { cards } = this.state;

        const list = [...cards];
        list.splice(i, 1);
        this.setState({
            cards: list,
        });
    };

    handleSubmit = async (e) => {
        e.preventDefault();

        const { user } = this.props;

        this.setState({
            loading: true,
        });

        const json = {
            customer: {
                nickname: user.nickname,
                ci: user.ci,
                tel: user.tel,
                directions: this.state.directions,
                cards_attributes: this.state.cards,
            },
        };

        console.log(json);

        try {
            const config = {
                headers: this.props.headers,
            };
            const response = await postData('edit-user/', json, config);
            this.props.updateHeaders(response.headers);
            this.props.updateUser(response.data);
            toastSuccess('Datos actualizados');
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        } catch (err) {
            console.log(err);
            toastError('Error, no se pudo actualizar');
            this.setState({
                loading: false,
            });
        }
    };

    render() {
        const { loading, cards } = this.state;

        const list_cards = cards.map((x, i) => (
            <div className="form-row mt-2" key={i}>
                <div className="col-sm-6 form-group">
                    <label htmlFor="name">Nombre</label>
                    <input
                        type="text"
                        name="name"
                        value={x.name}
                        className="form-control"
                        required
                        onChange={(e) => this.handleCardChange(e, i)}
                    />
                </div>

                <div className="col-sm-6 form-group">
                    <label htmlFor="number">Numero de tarjeta</label>
                    <input
                        type="number"
                        name="number"
                        value={x.number}
                        className="form-control"
                        required
                        onChange={(e) => this.handleCardChange(e, i)}
                        min={0}
                    />
                </div>

                <div className="col-sm-6 form-group">
                    <label htmlFor="month">MM</label>
                    <input
                        type="number"
                        name="month"
                        value={x.month}
                        className="form-control"
                        required
                        onChange={(e) => this.handleCardChange(e, i)}
                        min={1}
                        max={12}
                    />
                </div>

                <div className="col-sm-6 form-group">
                    <label htmlFor="year">AA</label>
                    <input
                        type="number"
                        name="year"
                        value={x.year}
                        className="form-control"
                        required
                        onChange={(e) => this.handleCardChange(e, i)}
                        min={0}
                        max={99}
                    />
                </div>

                <div className="col-sm-6 form-group">
                    <label htmlFor="cvc">CVC</label>
                    <input
                        type="number"
                        name="cvc"
                        value={x.cvc}
                        className="form-control"
                        required
                        onChange={(e) => this.handleCardChange(e, i)}
                        min={0}
                    />
                </div>

                <div className="btn-box mt-3 dinam-button-box col-12">
                    {cards.length !== 0 && (
                        <button
                            className="btn btn-cart"
                            rel="tooltip"
                            title="Eliminar"
                            onClick={(e) => {
                                this.handleRemoveCards(e, i);
                            }}
                        >
                            -
                        </button>
                    )}
                    {cards.length - 1 === i && (
                        <button className="btn btn-cart" rel="tooltip" title="Agregar" onClick={this.handleAddCards}>
                            +
                        </button>
                    )}
                </div>
            </div>
        ));

        return (
            <div className="modal-dialog modal-dialog-centered w-vista-r" role="document">
                <div className="modal-content quick-view-modal fluio-font">
                    <div>
                        <div className="header-title tac-desk">Tarjetas guardadas</div>

                        {loading ? (
                            <div className="row m-0">
                                <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/loading.gif`} className="img-fluid mb-4" />
                                    <h3>Cargando</h3>
                                </div>
                            </div>
                        ) : (
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-row margin-zero" />
                                <div className="container-button-prod">
                                    {list_cards}

                                    {list_cards.length == 0 ? (
                                        <div className="btn-box">
                                            <button className="btn btn-cart" onClick={this.handleAddCards}>
                                                +
                                            </button>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    <button className="btn modal-btn-add ttn mt-2" style={{ width: '100%' }}>
                                        Guardar
                                    </button>
                                </div>
                            </form>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.customer.data,
    headers: state.customer.headers,
});

export default connect(
    mapStateToProps,
    { updateHeaders, updateUser }
)(withRouter(CardsForm));
