import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withTranslate } from 'react-redux-multilingual';
import { getData, getCartLength } from '../../../../servicios';
import { filterCategory, updateCategories, clearOrden, dataLogout } from '../../../../actions';
import { connect } from 'react-redux';
import store from '../../../../store';
import cartIcon from '../.././../../assets/images/cart.png';
import Modal from 'react-responsive-modal';
import LocationForm from '../../../Modals/LocationForm';
import { getLocationCategories, getLocationHalls, getLocationText } from '../../../../funciones/cities';
import { getNavGreetText } from '../../../../funciones/nav-menu';
import { formatNumber } from '../../../../funciones/general';
import BonoForm from '../../../pages/BonoForm';
import AddressForm from '../../../Modals/AddressForm';
import CardsForm from '../../../Modals/CardsForm';

class NavBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            navClose: { right: '0px' },
            categories: [],
            openLocation: false,
            openBono: false,
            openAddress: false,
            openCards: false,
        };
    }

    closeLocation = () => {
        this.setState({
            openLocation: false,
        });
    };

    openLocation = (e) => {
        e.preventDefault();
        this.setState({
            openLocation: true,
        });
    };

    openModalBono = (e) => {
        e.preventDefault();
        this.setState({ openBono: true });
    };

    closeModalBono = () => {
        this.setState({ openBono: false });
    };

    openCards = (e) => {
        e.preventDefault();
        this.setState({ openCards: true });
    };

    closeModalCards = () => {
        this.setState({ openCards: false });
    };

    openAddress = (e) => {
        e.preventDefault();
        this.setState({ openAddress: true });
    };

    closeModalAddress = () => {
        this.setState({ openAddress: false });
    };

    async componentWillMount() {
        if (window.innerWidth < 750) {
            this.setState({ navClose: { right: '-410px' } });
        }
        if (window.innerWidth < 1199) {
            this.setState({ navClose: { right: '-300px' } });
        }
    }

    openNav() {
        this.setState({ navClose: { right: '0px' } });
        // let cuenta = document.getElementById('cuenta');
        // if (this.props.ordenes > 0 && !cuenta.nextElementSibling.classList.contains('opensubmenu')) {
        //     cuenta.click();
        // }
    }
    closeNav() {
        this.setState({ navClose: { right: '-410px' } });
    }

    onMouseEnterHandler() {
        if (window.innerWidth > 1199) {
            document.querySelector('#main-menu').classList.add('hover-unset');
        }
    }

    handleSubmenu = (event) => {
        event.preventDefault();
        if (event.target.classList.contains('sub-arrow')) return;

        if (event.target.nextElementSibling.classList.contains('opensubmenu'))
            event.target.nextElementSibling.classList.remove('opensubmenu');
        else {
            document.querySelectorAll('.nav-submenu').forEach(function(value) {
                value.classList.remove('opensubmenu');
            });
            //document.querySelector('.mega-menu-container').classList.remove('opensubmenu')
            event.target.nextElementSibling.classList.add('opensubmenu');
        }
    };

    handleMegaSubmenu = (event) => {
        if (event.target.classList.contains('sub-arrow')) return;

        if (event.target.parentNode.nextElementSibling.classList.contains('opensubmegamenu'))
            event.target.parentNode.nextElementSibling.classList.remove('opensubmegamenu');
        else {
            document.querySelectorAll('.menu-content').forEach(function(value) {
                value.classList.remove('opensubmegamenu');
            });
            event.target.parentNode.nextElementSibling.classList.add('opensubmegamenu');
        }
    };

    goByCategory = (e, category) => {
        e.preventDefault();
        this.closeNav();
        //this.props.filterCategory([category])
        this.props.history.push('/categoria/' + category);
        //window.location.reload()
    };

    goByPasillo = (e, pasillo) => {
        e.preventDefault();
        this.closeNav();
        this.props.history.push('/pasillo/' + pasillo);
        //window.location.reload()
    };

    goProducts = (e) => {
        e.preventDefault();
        this.props.filterCategory([]);
        this.props.history.push('/productos');
        //window.location.reload()
    };

    goAllAliados = (e) => {
        e.preventDefault();
        this.closeNav();
        this.props.history.push('/nuestros-aliados');
    };

    goPerfil = (e) => {
        e.preventDefault();
        this.closeNav();
        this.props.history.push('/profile');
    };

    goPedidos = (e) => {
        e.preventDefault();
        this.closeNav();
        this.props.history.push('/orders');
    };

    goRepeat = (e) => {
        e.preventDefault();
        this.closeNav();
        this.props.history.push('/orders?tab=2');
    };

    goLogin = (e) => {
        e.preventDefault();
        this.closeNav();
        this.props.history.push('/login');
    };

    goRegister = (e) => {
        e.preventDefault();
        this.closeNav();
        this.props.history.push('/register');
    };

    goAllAliados = (e) => {
        e.preventDefault();
        this.closeNav();
        this.props.history.push('/nuestros-aliados');
    };

    goTYC = (e) => {
        e.preventDefault();
        this.closeNav();
        this.props.history.push('/terminos-y-condiciones');
    };

    logout = (e) => {
        e.preventDefault();

        const { dataLogout, clearOrden } = this.props;
        clearOrden();
        dataLogout();
        this.props.history.push(process.env.PUBLIC_URL + '/');
    };

    render() {
        const { categories, tamaño, customer, ordenes, halls, greetText } = this.props;
        const { openBono, openAddress, openCards } = this.state;

        const list_menu_categories = categories
            .filter((value) => value.pri < 77)
            .map((val_c, in_c) => {
                return (
                    <div className="col mega-box" key={in_c}>
                        <div className="link-section">
                            <div className="menu-title">
                                <div className="menu-title mb-2" style={{ display: 'flex' }}>
                                    <h5
                                        onClick={(e) => {
                                            this.goByCategory(e, val_c.seo);
                                        }}
                                        className="menu-category-label"
                                    >
                                        {val_c.name}
                                    </h5>
                                    <span className="pasillo-plus nav-display" onClick={(e) => this.handleMegaSubmenu(e)}>
                                        +
                                    </span>
                                </div>

                                <div className="menu-content">
                                    <ul>
                                        {halls
                                            .filter((hall) => hall.category_id == val_c.id)
                                            .map((val, i) => (
                                                <li key={i}>
                                                    <Link
                                                        to={`${process.env.PUBLIC_URL}/#`}
                                                        onClick={(e) => {
                                                            this.goByPasillo(e, val.seo);
                                                        }}
                                                    >
                                                        {val.name}
                                                    </Link>
                                                </li>
                                            ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });

        return (
            <div>
                <img
                    onClick={() => window.location.replace(process.env.PUBLIC_URL + '/')}
                    src={`${process.env.PUBLIC_URL}/assets/images/icon/mobile-logo.png`}
                    alt="lobo-mobile"
                    className="fixed-logo mb-display"
                />

                <Link to={`${process.env.PUBLIC_URL}/cart`} className="cart-link mb-display">
                    {/*
                    <div className="cart-badge">
                        {tamaño}
                    </div>
                    <i className="fa fa-shopping-cart header-cart-icon"/>
                    */}

                    <div>
                        <img src={cartIcon} alt="cart" className="cart-img" />
                        <span className="cart-label">{tamaño}</span>
                    </div>
                </Link>

                <Link to={`${process.env.PUBLIC_URL}/`} className="home-link mb-display">
                    <i className="fa fa-home" />
                </Link>

                <div className="main-navbar">
                    <div id="mainnav">
                        <div className="toggle-nav" onClick={this.openNav.bind(this)}>
                            {/*
                            <i className="fa fa-bars sidebar-bar fix-side"></i>
                            */}
                            <div className="burger-div">
                                <div />
                                <div />
                                <div />
                            </div>
                            {this.props.ordenes > 0 ? <div className="pendiente-badge">{ordenes}</div> : ''}
                        </div>
                        <ul className="nav-menu nav-menu-style" style={this.state.navClose}>
                            <li className="back-btn" onClick={this.closeNav.bind(this)}>
                                <div className="mobile-back text-right cerrar-style">
                                    <span className="ttn">{greetText}</span>
                                    <i className="fa fa-times close-nav-icon-style pl-2" aria-hidden="true" />
                                </div>
                            </li>

                            <li className="mb-display nav-area-card">
                                <div className="nav-menu-title">Resumen de la cuenta</div>
                                {customer ? (
                                    <>
                                        <Link to="/profile" className="ttn nav-menu-item mt-4" onClick={this.goPerfil}>
                                            <i className="fa fa-user nav-item-icon" aria-hidden="true" />
                                            Mi cuenta
                                        </Link>
                                        <Link to="#" className="ttn nav-menu-item mt-4" onClick={this.openModalBono}>
                                            <i className="fa fa-dollar nav-item-icon" aria-hidden="true" />
                                            Mi saldo: ${formatNumber(customer.saldo)}
                                        </Link>
                                        <Link to="#" className="ttn nav-menu-item mt-4" onClick={this.openAddress}>
                                            <i className="fa fa-map-marker nav-item-icon" aria-hidden="true" />
                                            Libreta de direcciones
                                        </Link>
                                        <Link to="#" className="ttn nav-menu-item mt-4" onClick={this.openCards}>
                                            <i className="fa fa-credit-card nav-item-icon" aria-hidden="true" />
                                            Tarjetas guardadas
                                        </Link>
                                    </>
                                ) : (
                                    <>
                                        <Link
                                            onClick={this.goLogin}
                                            to={`${process.env.PUBLIC_URL}/login`}
                                            className="ttn nav-menu-item mt-4"
                                        >
                                            Login
                                        </Link>

                                        <Link
                                            onClick={this.goRegister}
                                            to={`${process.env.PUBLIC_URL}/register`}
                                            className="ttn nav-menu-item mt-4"
                                        >
                                            Registro
                                        </Link>
                                    </>
                                )}
                            </li>

                            {customer && (
                                <li className="mb-display nav-area-card">
                                    <div className="nav-menu-title">Mis pedidos</div>
                                    <Link to="#" className="ttn nav-menu-item mt-4" onClick={this.goPedidos}>
                                        <i className="fa fa-dropbox nav-item-icon" aria-hidden="true" />
                                        Mis pedidos
                                    </Link>
                                    <Link to="#" className="ttn nav-menu-item mt-4" onClick={this.goRepeat}>
                                        <i className="fa fa-repeat nav-item-icon" aria-hidden="true" />
                                        Pidelo de nuevo
                                    </Link>
                                </li>
                            )}

                            <li className="mb-display nav-area-card">
                                <div className="nav-menu-title">Tienda</div>

                                <div className="nav-li mt-4">
                                    <a href="#" className="nav-link nav-menu-item ttn" onClick={this.openLocation}>
                                        <i className="fa fa-map-marker location-icon" />
                                        {this.props.locationText}
                                    </a>
                                </div>
                                <div className="nav-li mt-4">
                                    <Link to="#" className="nav-link ttn nav-menu-item" onClick={(e) => this.handleSubmenu(e)}>
                                        Contacto
                                        <span className="sub-arrow nav-arrow-style" />
                                    </Link>
                                    <ul className="nav-submenu">
                                        <p>
                                            <li>
                                                <a href="tel:+573107139466" className=" ttn nav-drop-item">
                                                    <i className="fa fa-phone" />
                                                    (+57) 3107139466
                                                </a>
                                            </li>
                                            <li>
                                                <a href="mailto:info@fluio.com.co" className=" ttn nav-drop-item">
                                                    <i className="fa fa-envelope-o" />
                                                    info@fluio.com.co
                                                </a>
                                            </li>
                                        </p>
                                    </ul>
                                </div>

                                <div className="mega-menu nav-li mt-4">
                                    <Link to="#" className="dropdown nav-menu-item" onClick={(e) => this.handleSubmenu(e)}>
                                        Categorias
                                        <span className="sub-arrow nav-arrow-style" />
                                    </Link>
                                    <div className="mega-menu-container">
                                        <div className="container mt-2">
                                            <div className="row">{list_menu_categories}</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="nav-li mt-4">
                                    <Link to="#" className="nav-link ttn nav-menu-item" onClick={(e) => this.handleSubmenu(e)}>
                                        ¿Quiénes somos?
                                        <span className="sub-arrow nav-arrow-style" />
                                    </Link>
                                    <ul className="nav-submenu drop-nav over-navmenu pl-3 mt-2">
                                        <p className="ttn">
                                            Creemos que un mundo mejor es posible y que todos tenemos algo que aportar. Nuestra misión es
                                            facilitarte ese aporte a la ecología, a tu salud y a la sociedad.
                                        </p>
                                        <p className="ttn">
                                            Las marcas y tiendas que nos acompañan se guían por estándares éticos, que van desde permitir
                                            llevar una vida más natural, hasta fomentar el comercio local y justo.
                                        </p>
                                        <p className="ttn">
                                            Confiamos en una sociedad que elige productos que, en todo su ciclo de producción y vida, son
                                            respetuosos con el medio ambiente y con nuestro cuerpo.
                                        </p>
                                        <p className="ttn">
                                            Por eso concentramos toda la oferta saludable, orgánica, natural y sostenible en un solo lugar y
                                            que reemplazar tu consumo convencional por uno más ético, te sea posible.
                                        </p>
                                    </ul>
                                </div>

                                <div className="nav-li mt-4">
                                    <Link to="#" className="nav-link ttn nav-menu-item" onClick={(e) => this.handleSubmenu(e)}>
                                        ¿Por qué escogernos?
                                        <span className="sub-arrow nav-arrow-style" />
                                    </Link>
                                    <ul className="nav-submenu drop-nav over-navmenu pl-3 mt-2">
                                        <p>Queremos hacerte la vida más fácil, armónica y equilibrada.</p>
                                        <p>
                                            Muchos de nosotros somos conscientes de los productos no tan sanos que consumimos, el
                                            desperdicio que generamos y lo que contaminamos con nuestro ritmo de consumo. Para evitar esto y
                                            generar entre todos nuevos hábitos de consumo, te ofrecemos todo lo que necesitas para tu
                                            alimentación, para tu aseo personal y para tu hogar en un solo lugar.
                                        </p>
                                        <p>
                                            ¡Es muy fácil! Seleccionas los productos, pagas y se te entrega en la puerta de tu casa en 90
                                            minutos o programado.
                                        </p>
                                        <p>
                                            Garantizamos precios justos y asequibles, para que, llevar una vida natural y sana no te resulte
                                            tan costoso.
                                        </p>
                                        <p>Si somos más, cambiaremos más. Tú cuerpo y el planeta te lo agradecen. 🙂</p>
                                    </ul>
                                </div>

                                <div className="nav-li mt-4">
                                    <Link to="#" className="nav-link ttn nav-menu-item" onClick={this.goAllAliados}>
                                        Aliados
                                    </Link>
                                </div>

                                <div className="mb-display nav-li mt-4">
                                    <Link to="/terminos-y-condiciones" className="nav-link nav-menu-item ttn" onClick={this.goTYC}>
                                        Términos y condiciones
                                    </Link>
                                </div>
                            </li>

                            {customer && (
                                <div className="tac mb-5">
                                    <button className="btn btn-fluio-outline" onClick={this.logout}>
                                        Cerrar sesión
                                    </button>
                                </div>
                            )}
                        </ul>
                    </div>
                </div>

                <Modal
                    open={this.state.openLocation}
                    onClose={this.closeLocation}
                    center
                    classNames={{
                        modal: 'modal-mobile-style',
                        closeButton: 'close-forgotten',
                    }}
                    showCloseIcon={false}
                    closeOnOverlayClick={false}
                >
                    <LocationForm close={this.closeLocation} />
                </Modal>
                <Modal
                    open={openBono}
                    onClose={this.closeModalBono}
                    classNames={{ closeButton: 'close-forgotten', modal: 'mb-modal' }}
                    center
                >
                    <BonoForm close={this.closeModalBono} />
                </Modal>
                <Modal
                    open={openAddress}
                    onClose={this.closeModalAddress}
                    classNames={{ closeButton: 'close-forgotten', modal: 'mb-modal' }}
                    center
                >
                    <AddressForm close={this.closeModalAddress} />
                </Modal>
                <Modal
                    open={openCards}
                    onClose={this.closeModalCards}
                    classNames={{ closeButton: 'close-forgotten', modal: 'mb-modal' }}
                    center
                >
                    <CardsForm close={this.closeModalCards} />
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    filters: state.filters,
    categories: getLocationCategories(),
    halls: getLocationHalls(),
    tamaño: getCartLength(),
    customer: state.customer.data,
    ordenes: state.orden.pendientes.length,
    locationText: getLocationText(),
    greetText: getNavGreetText(),
    //ordenes: 1
});

export default connect(
    mapStateToProps,
    { filterCategory, updateCategories, clearOrden, dataLogout }
)(withRouter(NavBar));
