import React, { Component } from 'react';
import Breadcrumb from '../common/breadcrumb';
import FormError from '../common/FormError';
import 'react-toastify/dist/ReactToastify.min.css';
import { toastSuccess, toastError } from '../../alertas';
import { withRouter } from 'react-router-dom';
import { postData } from '../../servicios';
import { connect } from 'react-redux';
import { updateHeaders, updateUser } from '../../actions';
import { updateSaldo } from '../../services';
import Modal from 'react-responsive-modal';
import PasswordForm from './PasswordForm';
import MapTool from '../common/MapTool';
import BonoForm from './BonoForm';

class Perfil extends Component {
    constructor(props) {
        super(props);
        const { user } = this.props;

        if (user.cards.length > 0) {
            this.state = {
                name: user.name,
                email: user.email,
                tel: user.tel,
                ci: user.ci,
                nickname: user.nickname,
                //dir:user.addresses[0].dir,

                cards: user.cards,
                bool: false,
                open: false,
                openBono: false,
                openMap: false,
                //lon:user.addresses[0].lon,
                //lat:user.addresses[0].lat,
                directions: user.directions,

                bool_directions: false,

                //boolpass:false,
                //boolemail:false
            };
        } else {
            this.state = {
                name: user.name,
                email: user.email,
                tel: user.tel,
                ci: user.ci,
                nickname: user.nickname,
                //dir:user.addresses[0].dir,

                cards: user.cards,
                bool: false,
                open: false,
                openBono: false,
                openMap: false,
                //lon:user.addresses[0].lon,
                //lat:user.addresses[0].lat,
                directions: user.directions,

                bool_directions: false,

                //boolpass:false,
                //boolemail:false
            };
        }
    }

    update = async (e) => {
        e.preventDefault();

        //reinicia errores que pueden o no haber sido corregidos
        this.setState({
            boolemail: false,
            boolpass: false,
            bool_directions: false,
            bool: true,
        });

        if (this.state.directions.length == 0) {
            this.setState({
                bool_directions: true,
                bool: false,
            });
            return;
        }

        //validacion de que las contraseñas coincidan

        const json = {
            customer: {
                nickname: this.state.nickname,
                ci: this.state.ci,
                tel: this.state.tel,
                directions: this.state.directions,
                cards_attributes: this.state.cards,
                /**
                addresses_attributes:[{
                    dir: this.state.dir
                }],
                */
            },
        };

        if (this.state.lat) {
            json.customer.addresses_attributes = [
                {
                    dir: this.state.dir,
                    lat: this.state.lat,
                    lon: this.state.lon,
                },
            ];
        }

        //console.log(json)

        this.setState(
            {
                bool: true,
            },
            async () => {
                try {
                    const config = {
                        headers: this.props.headers,
                    };
                    const response = await postData('edit-user/', json, config);
                    console.log(response);
                    this.props.updateHeaders(response.headers);
                    this.props.updateUser(response.data);
                    toastSuccess('Datos actualizados');
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                } catch (err) {
                    console.log(err);
                    toastError('Error, no se pudo actualizar');
                    this.setState({
                        bool: false,
                    });
                }
            }
        );
    };

    openModal = (e) => {
        e.preventDefault();
        this.setState({ open: true });
    };

    closeModal = () => {
        this.setState({ open: false });
    };

    openModalBono = (e) => {
        e.preventDefault();
        this.setState({ openBono: true });
    };

    closeModalBono = () => {
        this.setState({ openBono: false });
    };

    openMap = (e) => {
        e.preventDefault();
        this.setState({ openMap: true });
    };

    closeMap = () => {
        this.setState({ openMap: false });
    };

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
        });
    };

    componentDidMount() {
        updateSaldo();
    }

    setCordenates = (lon, lat) => {
        this.setState({
            lon: lon,
            lat: lat,
            openMap: false,
        });
    };

    handleDirectionChange = (e, index) => {
        const { name, value, type } = e.target;
        const { directions } = this.state;

        const list = [...directions];
        list[index] = value;
        this.setState({
            directions: list,
        });
    };

    handleAdd = (e) => {
        e.preventDefault();
        const { directions } = this.state;
        this.setState({
            directions: [...directions, ''],
        });
    };

    handleRemove = (e, i) => {
        e.preventDefault();
        const { directions } = this.state;

        const list = [...directions];
        list.splice(i, 1);
        this.setState({
            directions: list,
        });
    };

    handleCardChange = (e, index) => {
        const { name, value, type } = e.target;
        const { cards } = this.state;
        const list = [...cards];

        list[index][name] = value;

        this.setState({
            cards: list,
        });
    };

    handleAddCards = (e) => {
        e.preventDefault();
        const { cards } = this.state;
        this.setState({
            cards: [
                ...cards,
                {
                    name: '',
                    number: '',
                    month: '',
                    year: '',
                    cvc: '',
                    df: true,
                },
            ],
        });
    };

    handleRemoveCards = (e, i) => {
        e.preventDefault();
        const { cards } = this.state;

        const list = [...cards];
        list.splice(i, 1);
        this.setState({
            cards: list,
        });
    };

    render() {
        const { boolemail, bool, open, directions, bool_directions, openBono, cards } = this.state;

        const list_cards = cards.map((x, i) => (
            <div className="form-row mt-2" key={i}>
                <div className="col-sm-6 form-group">
                    <label htmlFor="name">Nombre</label>
                    <input
                        type="text"
                        name="name"
                        value={x.name}
                        className="form-control"
                        required
                        onChange={(e) => this.handleCardChange(e, i)}
                    />
                </div>

                <div className="col-sm-6 form-group">
                    <label htmlFor="number">Numero de tarjeta</label>
                    <input
                        type="number"
                        name="number"
                        value={x.number}
                        className="form-control"
                        required
                        onChange={(e) => this.handleCardChange(e, i)}
                        min={0}
                    />
                </div>

                <div className="col-sm-6 form-group">
                    <label htmlFor="month">MM</label>
                    <input
                        type="number"
                        name="month"
                        value={x.month}
                        className="form-control"
                        required
                        onChange={(e) => this.handleCardChange(e, i)}
                        min={1}
                        max={12}
                    />
                </div>

                <div className="col-sm-6 form-group">
                    <label htmlFor="year">AA</label>
                    <input
                        type="number"
                        name="year"
                        value={x.year}
                        className="form-control"
                        required
                        onChange={(e) => this.handleCardChange(e, i)}
                        min={0}
                        max={99}
                    />
                </div>

                <div className="col-sm-6 form-group">
                    <label htmlFor="cvc">CVC</label>
                    <input
                        type="number"
                        name="cvc"
                        value={x.cvc}
                        className="form-control"
                        required
                        onChange={(e) => this.handleCardChange(e, i)}
                        min={0}
                    />
                </div>

                <div className="btn-box mt-3 dinam-button-box col-12">
                    {cards.length !== 0 && (
                        <button
                            className="btn btn-cart"
                            rel="tooltip"
                            title="Eliminar"
                            onClick={(e) => {
                                this.handleRemoveCards(e, i);
                            }}
                        >
                            -
                        </button>
                    )}
                    {cards.length - 1 === i && (
                        <button className="btn btn-cart" rel="tooltip" title="Agregar" onClick={this.handleAddCards}>
                            +
                        </button>
                    )}
                </div>
            </div>
        ));

        const list_directions = directions.map((x, i) => {
            return (
                <div className="form-row mt-2" key={i}>
                    <div className="col-sm-12 form-group margin-zero">
                        <label htmlFor="name">Dirección</label>
                        <input
                            type="text"
                            name="direction"
                            value={x}
                            className="form-control mb-2"
                            required
                            onChange={(e) => this.handleDirectionChange(e, i)}
                        />
                    </div>

                    <div className="btn-box dinam-button-box mb-2">
                        {directions.length !== 0 && (
                            <button
                                className="btn btn-cart"
                                onClick={(e) => {
                                    this.handleRemove(e, i);
                                }}
                            >
                                -
                            </button>
                        )}
                        {directions.length - 1 === i && (
                            <button className="btn btn-cart" onClick={this.handleAdd}>
                                +
                            </button>
                        )}
                    </div>
                </div>
            );
        });

        return (
            <div>
                <Breadcrumb title={'Editar Perfil'} />

                {/*Regsiter section*/}
                <section className="register-page section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h3 className="ttn">Datos de la cuenta</h3>
                                <div className="theme-card">
                                    <form className="theme-form" onSubmit={this.update}>
                                        <h4 className="tac">Datos personales</h4>

                                        <div className="form-row">
                                            <div className="col-md-6 form-group">
                                                <label htmlFor="name">Nombre</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="name"
                                                    placeholder="Nombre completo"
                                                    value={this.state.name}
                                                    onChange={this.handleChange}
                                                    disabled
                                                    required
                                                />
                                            </div>

                                            <div className="col-md-6 form-group">
                                                <label htmlFor="email">Correo</label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    value={this.state.email}
                                                    name="email"
                                                    placeholder="Correo electrónico"
                                                    onChange={this.handleChange}
                                                    disabled
                                                    required
                                                />
                                                <FormError text="El correo indicado ya esta en uso" show={boolemail} />
                                            </div>
                                        </div>

                                        {/*

                                        <div className="form-row">

                                            <div className="col-md-6 form-group">
                                                <label htmlFor="password">Contraseña</label>
                                                <input type="password" className="form-control" name="password" placeholder="Contraseña" onChange={this.handleChange} required />
                                                <FormError text="Las contraseñas no coinciden" show={boolpass}/>
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <label htmlFor="password_confirmation" className="ttn">Confirmar contraseña</label>
                                                <input type="password" className="form-control" name="password_confirmation" placeholder="Confirma la contraseña" onChange={this.handleChange} required />
                                            </div>

                                        </div>

                                        */}

                                        <div className="form-row">
                                            <div className="col-md-6 form-group">
                                                <label htmlFor="tel">Celular</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="tel"
                                                    value={this.state.tel}
                                                    placeholder="Celular"
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <label htmlFor="ci">Cédula</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="ci"
                                                    value={this.state.ci}
                                                    placeholder="Cédula"
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="form-row">
                                            <div className="col-md-6 form-group">
                                                <label htmlFor="nickname">Usuario</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="nickname"
                                                    value={this.state.nickname}
                                                    placeholder="Nombre de usuario"
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </div>

                                            {/*

                                            <div className="col-md-6 form-group">
                                                <label htmlFor="dir" className="ttn">Direccíon de envío</label>
                                                <input type="text" className="form-control" name="dir" placeholder="Dirección" value={this.state.dir} onChange={this.handleChange} required />
                                            </div>

                                            */}

                                            <div className="col-md-6 form-group">
                                                <label htmlFor="saldo">Saldo</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="saldo"
                                                    value={this.props.user.saldo}
                                                    disabled
                                                />
                                            </div>
                                        </div>

                                        <div className="form-row">
                                            {/*
                                            
                                            <div className="col-md-6 form-group">
                                                <label htmlFor="lat">Ubicacion</label>
                                                <input type="text" className="form-control" name="lat" 
                                                value={this.state.lat?"Lat:"+this.state.lat+" "+"Lon:"+this.state.lon:"Sin ubicación"}  
                                                disabled />
                                            </div>


                                            */}
                                        </div>

                                        <div className="form-row">
                                            <div className="col-6 form-group">
                                                <button type="button" className="btn btn-solid btn-center ttn" onClick={this.openModal}>
                                                    Cambiar contraseña
                                                </button>
                                            </div>

                                            {/*

                                            <div className="col-6 form-group">

                                                <button className="btn btn-solid btn-center ttn" onClick={this.openMap}>
                                                    Ver mapa
                                                    <i className="fa fa-map-marker map-icon"/>
                                                </button>  

                                            </div>

                                            */}
                                        </div>

                                        <h4 className="tac">Direcciones</h4>

                                        {list_directions}

                                        {list_directions.length == 0 ? (
                                            <div className="btn-box">
                                                <button className="btn btn-cart" onClick={this.handleAdd}>
                                                    +
                                                </button>
                                            </div>
                                        ) : (
                                            ''
                                        )}

                                        <div className="mt-2">
                                            <FormError text="Debes incluir al menos 1 dirección" show={bool_directions} />
                                        </div>

                                        <h4 className="tac mt-1 ttn">
                                            (Opcional) Tarjetas de crédito, registralas si quieres usar este metodo de pago
                                        </h4>
                                        <h5 className="tac font-10 ttn">
                                            - Toda tu informacion esta protegida por medio de la pasarela de pago Kushki
                                            (https://www.kushkipagos.com) más informacion -
                                        </h5>

                                        <div className="container-button-prod">
                                            {list_cards}

                                            {list_cards.length == 0 && (
                                                <div className="btn-box">
                                                    <button className="btn btn-cart" onClick={this.handleAddCards}>
                                                        +
                                                    </button>
                                                </div>
                                            )}
                                        </div>

                                        <div className="form-row">
                                            <div className="col-md-6 form-group">
                                                <button
                                                    onClick={this.openModalBono}
                                                    type="button"
                                                    className="btn btn-solid btn-center mt-26px ttn"
                                                    disabled={bool}
                                                >
                                                    Canjear bono
                                                </button>
                                            </div>

                                            <div className="col-md-6 form-group">
                                                <button type="submit" className="btn btn-solid btn-center mt-26px ttn" disabled={bool}>
                                                    Actualizar Datos
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Modal
                    classNames={{ modal: 'mb-modal' }}
                    open={open}
                    onClose={this.closeModal}
                    classNames={{ closeButton: 'close-forgotten' }}
                    center
                >
                    <PasswordForm close={this.closeModal} />
                </Modal>

                <Modal
                    open={openBono}
                    onClose={this.closeModalBono}
                    classNames={{
                        closeButton: 'close-forgotten',
                        modal: 'mb-modal',
                    }}
                    center
                >
                    <BonoForm close={this.closeModalBono} />
                </Modal>

                <Modal
                    open={this.state.openMap}
                    onClose={this.closeMap}
                    center
                    classNames={{
                        modal: 'map-mb',
                        closeButton: 'close-forgotten',
                    }}
                >
                    <div className="modal-dialog modal-dialog-centered w-vista-r map-dialog" role="document">
                        <div className="modal-content quick-view-modal">
                            <div className="modal-body">
                                <MapTool submit={this.setCordenates} lat={this.state.lat} lon={this.state.lon} />
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.customer.data,
    headers: state.customer.headers,
});

export default connect(
    mapStateToProps,
    { updateHeaders, updateUser }
)(withRouter(Perfil));
